import { createApp } from 'vue'
import App from './App.vue'
import { router } from "@/router";
import { setupI18n } from '@/lang/lang'
// 导入vant
import vant from 'vant'
import { Tab, Tabs, Toast, Popup } from 'vant';
import 'vant/lib/index.css'

import VueClipBoard from 'vue-clipboard2'

//新增element3（目前只用了select组件， 在tab3.vue 第68行附近）
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 引入通用样式
import '@/styles/base.scss'


router.beforeEach(function (to, from, next) {
    if (to.meta.needLogin) {
        //页面是否登录
        if (sessionStorage.getItem('username')) {
            //本地存储中是否有token(uid)数据
            next(); //表示已经登录
        } else {
            //next可以传递一个路由对象作为参数 表示需要跳转到的页面
            next({
                name: "login"
            });
        }
    } else {
        //表示不需要登录
        next(); //继续往后走
    }
});

const app = createApp(App)

app.config.globalProperties.$configimgurl = 'https://f-tense.cc';





// 转盘完整加载
import VueLuckyCanvas from '@lucky-canvas/vue'


import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
    anchorPlacement: 'top-bottom',
  });

app.use(VueLuckyCanvas)
app.use(ElementPlus)
setupI18n(app)
app.use(Tab)
app.use(Tabs)
app.use(Toast)
app.use(Popup)
app.use(VueClipBoard)
app.use(router)

    .use(vant)
    .mount('#app')
