<template>
  <div class="container">
    <div class="homecontainer">
      <div class="top">
        <div class="name">
          <div class="text">{{ username }}</div>
        </div>
        <div class="title">{{ $t('home.Pionexpro') }}</div>
        <div class="ling-box">
          <img class="ling" src="../../static/img/home/ling.svg" />
        </div>
      </div>

      <div class="userinfo">
        <div class="text-box">
          <div class="text1">
            AI Quantification Trading
          </div>
          <div class="text2">
            Intelligent pre-built bots help you automate trades and earn profits around the clock
          </div>
        </div>
        <div class="p-list">
          <div class="list-item">
            <div class="value">318,817</div>
            <div class="text">Global Bot Traders</div>
          </div>
          <div class="list-item">
            <div class="value">480,144,328</div>
            <div class="text">Bots Created Worldwide</div>
          </div>
          <div class="list-item">
            <div class="value">104,819,912</div>
            <div class="text">Bot Trading Earnings</div>
          </div>
        </div>
      </div>
      <div class="notice-wrap">
        <van-notice-bar  left-icon="volume-o" color="#000000" background="#00000000" :scrollable="false">
          <van-swipe
              vertical
              class="notice-swipe"
              :autoplay="2000"
              :touchable="false"
              :show-indicators="false"
          >
          <van-swipe-item class="swipe-item">0xa1******8F93 Earn 1423 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0xb4******2A8E Earn 2159 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0xc9******10BD Earn 1783 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0xd3******7C41 Earn 1347 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0xe3******73Ca Earn 4871 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0xfa******BB32 Earn 1964 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0xb7******AB10 Earn 1123 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0x8d******CC97 Earn 3056 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0x6f******5AEE Earn 2278 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0xc2******7781 Earn 1421 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0x1e******44A9 Earn 3048 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0x91******DDBE Earn 1899 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0x7b******AAFE Earn 2687 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0x34******10EC Earn 3471 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0xcd******29F7 Earn 1319 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0x2a******66DF Earn 1973 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0xe0******88CB Earn 1542 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0x56******AB32 Earn 3210 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0xa9******DD24 Earn 2439 USDT</van-swipe-item>
<van-swipe-item class="swipe-item">0xff******7EE0 Earn 2186 USDT</van-swipe-item>

          </van-swipe>
        </van-notice-bar>
      </div>
      <div class="type-wrap">
        <div v-for="(item, index) in list.slice(0,3)" :key="index"
              style="width: 100%">
          <div class="item-li"
               :class="[animationArr.includes(index) ? 'activeAnim': '', animationArr1.includes(index+1) ? 'activeAnim1': '']">
            <div class="horizontal">
              <img class="coinimg" :src="$configimgurl + item[10]" />
              <div class="name">{{ item[0] }}<span>/{{ item[1] }}</span></div>
            </div>
            <div class="last">
              <p v-if="item[8] > 0" class="txtgreen">{{ item[2] }}</p>
              <p v-else class="txtred">{{ item[2] }}</p>
            </div>
            <div class="trend-box">
              <img
                  v-if="item[8] > 0"
                  class="greenimg"
                  src="../../static/new/home/green.png"
              />
              <img v-else class="redimg" src="../../static/new/home/red.png" />

              <p v-if="item[8] > 0" class="txtgreen">{{ item[8] }}</p>
              <p v-else class="txtred">{{ item[8] }}</p>
            </div>
          </div>

        </div>
      </div>

      <div class="rank-wrap">
        <div class="title-box">
          <div class="ran1">Name</div>
          <div class="ran2">Last</div>
          <div class="ran3">Change</div>
        </div>
        <div class="list-box">
          <div class="item-li-h" v-for="(item, index) in list.slice(3,13)" :key="index"
               :class="[animationArr.includes(index) ? 'activeAnim': '' , animationArr1.includes(index+1) ? 'activeAnim1': '']"
          >
            <div class="horizontal">
              <img class="coinimg" :src="$configimgurl + item[10]" />
              <div class="name">{{ item[0] }}<span>/{{ item[1] }}</span></div>
            </div>
            <div class="last">
              <p v-if="item[8] > 0" class="txtgreen">{{ item[2] }}</p>
              <p v-else class="txtred">{{ item[2] }}</p>
            </div>
            <div class="trend-box">
              <img
                  v-if="item[8] > 0"
                  class="greenimg"
                  src="../../static/new/home/green.png"
              />
              <img v-else class="redimg" src="../../static/new/home/red.png" />

              <p v-if="item[8] > 0" class="txtgreen">{{ item[8] }}</p>
              <p v-else class="txtred">{{ item[8] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="training-steps">The training steps of an AI model</div>
      <div class="video_class">
        <video
            v-if="iszidongbofang=='true'"
            class="videos"
            autoplay muted controls playsinline
            poster="../../static/new/home/pingbao.png"
        >
          <source src="../../static/new/home/ai.mp4" type="video/mp4" />
        </video>
        <video
            v-else
            class="videos"
            muted controls
            poster="../../static/new/home/pingbao.png"
        >
          <source src="../../static/new/home/ai.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="violet-tab">
        <van-swipe class="violet-swipe" :autoplay="3500" indicator-color="white">
          <van-swipe-item class="swipe-item">
            <div class="title">Step 1：Data collection and cleaning</div>
            <img src="../../static/new/home/1682746781_42394.png">
            <div class="text">Collect market data including stock prices trading volumes market sentiment indicators etc. and preprocess and clean the data to ensure its quality and consistency</div>
          </van-swipe-item>
          <van-swipe-item class="swipe-item">
            <div class="title">Step 2：Feature extraction and construction</div>
            <img src="../../static/new/home/1682746789_95062.png">
            <div class="text">Select features relevant to trading strategies such as technical indicators fundamental data market sentiment etc. and transform them into appropriate formats so that the model can understand them</div>
          </van-swipe-item>
          <van-swipe-item class="swipe-item">
            <div class="title">Step 3：Model selection</div>
            <img src="../../static/new/home/1682746795_95298.png">
            <div class="text">Choose a suitable model type such as recurrent neural networks convolutional neural networks support vector machines etc and set hyperparameters (such as learning rate regularization coefficient etc)</div>
          </van-swipe-item>
          <van-swipe-item class="swipe-item">
            <div class="title">Step 4：Model training</div>
            <img src="../../static/new/home/1682746804_66128.png">
            <div class="text">Divide the dataset into training and validation sets and use the training set to train the model During this process the model will adjust its weights and biases based on the training data to gradually improve its performance</div>
          </van-swipe-item>
          <van-swipe-item class="swipe-item">
            <div class="title">Step 5：Model evaluation and adjustment</div>
            <img src="../../static/new/home/1682746821_27401.png">
            <div class="text">Use the validation set to evaluate the model's performance and make adjustments to further improve its accuracy and generalization ability</div>
          </van-swipe-item>
          <van-swipe-item class="swipe-item">
            <div class="title">Step 6：Model deployment and inference</div>
            <img src="../../static/new/home/1682746826_99491.png">
            <div class="text">Use the validation set to evaluate the model's performance and make adjustments to further improve its accuracy and generalization ability</div>
          </van-swipe-item>
          <van-swipe-item class="swipe-item">
            <div class="title">Step 7：Backtesting and optimization</div>
            <img src="../../static/new/home/1682746832_38501.png">
            <div class="text">Use historical data for backtesting evaluate the model's performance in the past and optimize it to further improve its performance and stability</div>
          </van-swipe-item>
          <template #indicator="{ active, total }">
            <div class="custom-indicator">
              <div class="dot" :class="active === item-1? 'active':'' " v-for="item in 8" :key="item">
              </div>
            </div>
          </template>
        </van-swipe>
      </div>
      <div class="these-step">
        These steps often require multiple iterations and tweaks to get the best trading performance At the same time the data needs to be monitored and updated to ensure that the model continues to work efficiently
      </div>
      <div class="Help-Center">Help Center</div>
      <div class="help-wrap">
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item class="collapse-item" name="1">
            <template #title>
              <div class="title-box">
                <img class="icon" src="../../static/img/home/help.png" style="width: 20px;height: 20px">
                <div class="title">What is Intelligent AI Quantitative Trading </div>
              </div>
            </template>
            <template #default>
              <div class="text-content">Intelligent AI Quantitative Trading is a method of using artificial intelligence technology to automate investment decisions. It uses AI algorithms to analyze and process large amounts of market data to identify trading opportunities and implement trades through automated execution of trading strategies. Intelligent AI Quantitative Trading can improve trading efficiency, reduce trading costs, and reduce the impact of human errors, thereby improving investment returns.</div>
            </template>
          </van-collapse-item>
          <van-collapse-item class="collapse-item" name="2">
            <template #title>
              <div class="title-box">
                <img class="icon" src="../../static/img/home/help.png" style="width: 20px;height: 20px">
                <div class="title">Intelligent AI Quantitative Trading does not necessarily guarantee profits.</div>
              </div>
            </template>
            <template #default>
              <div class="text-content">Intelligent AI Quantitative Trading is not guaranteed to be profitable because there is uncertainty and volatility in the market, and the AI model may be limited by the training data and changes in the market environment. In addition, quantitative trading strategies may also be affected by other factors, such as market liquidity, trading costs, technical failures, and so on.</div>
            </template>
          </van-collapse-item>
          <van-collapse-item class="collapse-item" name="3">
            <template #title>
              <div class="title-box">
                <img class="icon" src="../../static/img/home/help.png" style="width: 20px;height: 20px">
                <div class="title">The advantages of intelligent AI quantitative trading.</div>
              </div>
            </template>
            <template #default>
              <div class="text-content">
                <span>Automated decision-making: AI models can automate the execution of trading strategies, reducing the impact of human decision-making and improving trading efficiency.<br><br>Data-driven: Utilizing big data and machine learning technology, AI models can analyze and process market data to identify more trading opportunities.<br><br>Scientificity: Quantitative trading is based on rigorous mathematical models and statistical analysis methods, which have a high degree of scientificity and reliability.<br><br>Programmability: Quantitative trading can be programmed, tested, and optimized to adapt to different market environments and requirements.<br><br>Risk control: Quantitative trading can control risks by setting stop-loss and risk management mechanisms, reducing investment losses.</span>
              </div>
            </template>
          </van-collapse-item>
          <van-collapse-item class="collapse-item" name="4">
            <template #title>
              <div class="title-box">
                <img class="icon" src="../../static/img/home/help.png" style="width: 20px;height: 20px">
                <div class="title">What is Weighted score？</div>
              </div>
            </template>
            <template #default>
              <div class="text-content">Weighted score is based on the user's total limit. After multiple uses of AI intelligent quantitative robots, AI judges their usage habits and contributions to model training, and automatically converts and increases the value. This value, under the premise of good credit status, can unlock various levels of functions and benefits of the platform, which is very important for users. It is the basic criterion for becoming an investor.</div>
            </template>
          </van-collapse-item>
        </van-collapse>
      </div>
      <div class="Partner-box">
        <div class="Partner">Partner</div>
        <div class="obp">Our business partner</div>
        <img src="../../static/img/home/home-tips.png" style="width: 350px;margin-top: 25px">
        <img class="shouzhang" src="../../static/img/home/shouzhang.jpeg" style="width: 60px;height: 60px">
        <div class="logos">
          <img src="../../static/img/home/metamast.png">
          <img src="../../static/img/home/defi.png">
          <img src="../../static/img/home/tp.png">
          <img src="../../static/img/home/im.png">
          <img src="../../static/img/home/bitkeep.png">
          <img src="../../static/img/home/safapay.png">
          <img src="../../static/img/home/trust.png">
          <img src="../../static/img/home/interactivebrokers-hp.f7b39f93.svg">
          <img src="../../static/img/home/oanda-hp-logo.b0e12736.svg">
          <img src="../../static/img/home/coinbase-hp.bcbfc086.svg">
          <img src="../../static/img/home/bitfinex-hp.69bc3f96.svg">
          <img src="../../static/img/home/tradier-hp.af8651ce.svg">
          <img src="../../static/img/home/tradingtech-hp.5a6029f2.svg" style="max-width: 200px;">
          <img src="../../static/img/home/terminal-link-hp.0b824c42.svg">
          <img src="../../static/img/home/binance-hp.7df65d7f.svg">
          <img src="../../static/img/home/kraken-hp.2a37eee2.svg">
          <img src="../../static/img/home/samco-hp.a193ce38.svg">
          <img src="../../static/img/home/zerodha-hp.bdff47cc.svg">
          <img src="../../static/img/home/exante-hp.f173a980.svg">
          <img src="../../static/img/home/ameritrade.c7c0b9a5.svg">
        </div>
      </div>

      <div class="Subscribe-box">
        <div class="Subscribe">Subscribe to our news</div>
        <div class="subscribe-wrap">
          <van-field class="subscribe-input" v-model="subscribeValue" placeholder="Please enter email address" />
          <div class="btn">Subscription</div>
        </div>
      </div>

      <div class="dash-line"></div>

      <div class="service-box">
        <img src="../../static/img/home/success.webp" style="width: 75px;height: 75px">
        <div class="title">All service are online</div>
        <div class="sub-title">You can fully use all functions</div>

        <div class="redandgreen">
          <div class="ragkuang">
            <div class="ragtitle">
              <p class="Website">Website</p>
              <p class="Uptime">99.806% Uptime</p>
            </div>
            <div class="rag14">
              <div
                  v-for="(item, index) in raglist"
                  :class="{ greenshubiao: index != 2, redshubiao: index == 2 }"
              ></div>
            </div>
          </div>

          <div class="ragkuang">
            <div class="ragtitle">
              <p class="Website">AIgorithm Lab</p>
              <p class="Uptime">100.000% Uptime</p>
            </div>
            <div class="rag14">
              <div
                  v-for="(item, index) in raglist"
                  :class="{ greenshubiao: index != 9, redshubiao: index == 9 }"
              ></div>
            </div>
          </div>

          <div class="ragkuang">
            <div class="ragtitle">
              <p class="Website">Community service</p>
              <p class="Uptime">99.958% Uptime</p>
            </div>
            <div class="rag14">
              <div
                  v-for="(item, index) in raglist"
                  :class="{ greenshubiao: index != 4, redshubiao: index == 4 }"
              ></div>
            </div>
          </div>

          <div class="ragkuang">
            <div class="ragtitle">
              <p class="Website">Coding</p>
              <p class="Uptime">99.958% Uptime</p>
            </div>
            <div class="rag14">
              <div
                  v-for="(item, index) in raglist"
                  :class="{ greenshubiao: index != 6, redshubiao: index == 6 }"
              ></div>
            </div>
          </div>

          <div class="ragkuang">
            <div class="ragtitle">
              <p class="Website">Backtesting</p>
              <p class="Uptime">100.000% Uptime</p>
            </div>
            <div class="rag14">
              <div
                  v-for="(item, index) in raglist"
                  :class="{ greenshubiao: item != 0, redshubiao: item == 0 }"
              ></div>
            </div>
          </div>

          <div class="ragkuang">
            <div class="ragtitle">
              <p class="Website">Live Trading</p>
              <p class="Uptime">100.000% Uptime</p>
            </div>
            <div class="rag14">
              <div
                  v-for="(item, index) in raglist"
                  :class="{ greenshubiao: item != 0, redshubiao: item == 0 }"
              ></div>
            </div>
          </div>

          <div class="ragkuang">
            <div class="ragtitle">
              <p class="Website">Parameter Optimization</p>
              <p class="Uptime">100.000% Uptime</p>
            </div>
            <div class="rag14">
              <div
                  v-for="(item, index) in raglist"
                  :class="{ greenshubiao: item != 0, redshubiao: item == 0 }"
              ></div>
            </div>
          </div>

          <div class="ragkuang">
            <div class="ragtitle">
              <p class="Website">Api Service</p>
              <p class="Uptime">100.000% Uptime</p>
            </div>
            <div class="rag14">
              <div
                  v-for="(item, index) in raglist"
                  :class="{ greenshubiao: item != 0, redshubiao: item == 0 }"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="dash-line"></div>

      <div class="HelpCenter-box">
        <div class="text">Help Center</div>
        <div class="text">6 Tips for Crypto Bot Trading Success</div>
        <div class="text">10 Steps for Profitable Trading Bots</div>
        <div class="text">Top 10 Technical Indicators for Trading Bots</div>
      </div>

      <div class="dash-line"></div>

      <div class="bottom-box">
        <div class="bottom-title">Nuropro</div>
        <div class="bottom-text">© 2022 Nuropro</div>
      </div>
    </div>
	
	<div @click="isxinxishow=true" style="width: 54px;
	height: 54px;
	border-radius: 45px;
	position: fixed;
	right: 15px;
	z-index: 2;top: 161px;">
		<img src="../../static/new/home/3f61d6a62dfdb297610799368b1da513.gif" />
	</div>
	
	<div @click="gotoservice" style="width: 54px;
    height: 54px;
    border-radius: 45px;
    position: fixed;
    right: 15px;
    z-index: 2;top: 218px;">
		<img src="../../static/new/home/1682697387_68624.png" />
	</div>
	
	<div v-if="isxinxishow" class="mask-news-info c">
		<div class="reg-model" style="background-color: rgba(0, 117, 253, 0.2); border-color: transparent;">
			<div style="text-align: center; font-size: 19px; padding: 10px 0px; font-weight: bold; color: rgb(0, 0, 0);">
				Personal information
			</div>
			<div class="ming-wrap col" style="border-bottom: 0.5px dashed transparent; background-color: rgba(0, 117, 253, 0.2); border-top-color: transparent; border-right-color: transparent; border-left-color: transparent;">
				<div class="item-li ac sb" style="display: flex; flex-direction: column; justify-content: flex-start; align-items: center;">
					<input class="usdInput" style="padding: 5px 10px; border-radius: 5px; font-size: 18px; width: 100%; height: 36px; background-color: rgb(255, 255, 255); color: rgb(51, 51, 51);" placeholder="Please enter nationality" />
					<input class="usdInput" style="padding: 5px 10px; border-radius: 5px; font-size: 18px; width: 100%; height: 36px; background-color: rgb(255, 255, 255); color: rgb(51, 51, 51);" placeholder="Please type in your name" />
					<input class="usdInput" style="padding: 5px 10px; border-radius: 5px; font-size: 18px; width: 100%; height: 36px; background-color: rgb(255, 255, 255); color: rgb(51, 51, 51);" placeholder="Please enter date" />
					<input class="usdInput" style="padding: 5px 10px; border-radius: 5px; font-size: 18px; width: 100%; height: 36px; background-color: rgb(255, 255, 255); color: rgb(51, 51, 51);" placeholder="Please enter the amount" />
					<input class="usdInput" style="padding: 5px 10px; border-radius: 5px; font-size: 18px; width: 100%; height: 36px; background-color: rgb(255, 255, 255); color: rgb(51, 51, 51);" placeholder="Please enter currency" />
					<input class="usdInput" style="padding: 5px 10px; border-radius: 5px; font-size: 18px; width: 100%; height: 36px; background-color: rgb(255, 255, 255); color: rgb(51, 51, 51);" placeholder="Please enter contact information" />
					<input class="usdInput" style="padding: 5px 10px; border-radius: 5px; font-size: 18px; width: 100%; height: 36px; background-color: rgb(255, 255, 255); color: rgb(51, 51, 51);" placeholder="Please enter address" />
				</div>
				<div @click="subtijiao" class="btn c fff f-30" style="margin-top: 9px; background-image: linear-gradient(to right, rgb(0, 117, 253), rgb(0, 117, 253)); font-weight: bold;">
					Confirm
				</div>
			</div>
		</div>
		<div @click="isxinxishow=false" style="width: 30px;height: 30px;font-size: 15px; text-align: center; padding: 7px; background-color: rgb(255, 255, 255); border-radius: 100%; display: flex; justify-content: center; align-items: center; margin-top: 5px;">
			<div class="u-icon u-icon--right">
				<p class="u-icon__icon uicon-close" style="font-size: 16px; line-height: 16px; font-weight: normal; top: 0px; color: rgb(96, 98, 102);">
					<span>X</span>
				</p>
			</div>
		</div>
	</div>
	
  </div>
</template>

<script>
import {toRefs, reactive, onMounted, getCurrentInstance, nextTick, onBeforeUnmount} from "vue";
import { useRouter } from "vue-router";

import { getallcoinList } from "@/api/api";
import { showToast } from "vant";
import * as echarts from 'echarts'


export default {
  setup() {
    let router = useRouter();

    const { proxy } = getCurrentInstance();
    // 数据
    const State = reactive({
      interval: null,
      animationArr:[],
      animationArr1:[],
      subscribeValue: '',
      activeName:[''],
      username: "",
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      raglist: Array.from({length:37}),
      query: {
        id: 1, //交易区，默认传1
        type: 0, //0 货币
      },
      list: [],
	  iszidongbofang:'',
	  isxinxishow:false
    });
    // 方法
    const Function = reactive({
      onload() {
        const usernames = sessionStorage.getItem("username");
        State.username = usernames.substr(-6);
		State.iszidongbofang = sessionStorage.getItem("iszidongbofang")
		console.log(State.iszidongbofang)
        getallcoinList(State.query).then((res) => {
          const { data } = res;
          State.list = data.url;
          //console.log(State.list);
		  if (State.list.length > 0) {
		      nextTick(() => {
		          State.list.forEach((item, index) => {
		              // Function.setEcharts(item, index)
		          })
		      })
		  }
        });

        State.interval = setInterval(()=>{
          State.animationArr = Function.generateRandomArray(Math.floor(Math.random() * 3), 0, 2)
          State.animationArr1 = Function.generateRandomArray(Math.floor(Math.random() * 10), 0, 9)
          console.log(State.animationArr.toString())
          console.log(State.animationArr1.toString())
          setTimeout(()=>{
            State.animationArr = []
            State.animationArr1 = []
          }, 600)
        },3000)
      },
	  subtijiao(){
		  State.isxinxishow = false
	  },
      generateRandomArray(length, min, max) {
        let arr = [];
        for(let i = 0; i < length; i++) {
          arr.push(Math.floor(Math.random() * (max - min + 1)) + min);
        }
        return arr;
      },
	  gotoservice(){
		  router.push({
		    path: "/service",
		  });
	  },
	  setEcharts(item, index) {
	      var x = []
	      var y = []
	      if (item.k && item.k.length > 0) {
	          item.k.forEach(itemdata => {
	              x.push(itemdata.time)
	              y.push(itemdata.value)
	          })

	          let colors = '#5fc88f'
	          if (item[6] > 0) {
	              colors = '#ff6464'
	          }
	          let myChart = echarts.init(document.getElementById("myChart" + index));

	          // 绘制图表
	          let option = {
	              xAxis: {
	                  data: x,
	                  show: false
	              },
	              yAxis: {
	                  show: false,
	                  splitLine: {
	                      show: false
	                  },
	                  axisLine: {
	                      show: false
	                  },
	                  min: function (value) {
	                      return value.min;
	                  }
	              },
	              tooltip: {
	                  show: false
	              },
	              series: [{
	                  type: 'line',//折线
	                  symbolSize: 0,
	                  smooth: false,//是否曲线显示
	                  data: y,
	                  lineStyle: {//线条的样式
	                      width: 2,
	                      color: colors
	                  },
	                  areaStyle: {
	                      color: {
	                          type: 'linear',
	                          x: 0,
	                          y: 0,
	                          x2: 0,
	                          y2: 1,
	                          colorStops: [{
	                              offset: 0,
	                              color: colors // 0% 处的颜色
	                          }, {
	                              offset: 1,
	                              color: 'rgba(6,37,55,0)' // 100% 处的颜色
	                          }],
	                          global: false // 缺省为 false
	                      }
	                  }
	              }]
	          }



	          myChart.setOption(option);

	      }

	  }

    });
    // 接口
    const Interface = reactive({});

    onMounted(() => {
      Function.onload();
    });
    onBeforeUnmount(() => {
      clearInterval(State.interval)
    });
    return {
      ...toRefs(State),
      ...toRefs(Function),
      ...toRefs(Interface),
    };
  },
};
</script>

<style scoped lang="scss">
.container {
	color: rgb(0, 0, 0);
}
.homecontainer {
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  background-image: url('../../static/img/home/home-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgb(255, 255, 255);
  font-weight: 600;
}
.top {
  height: 44px;
  background-image: url('../../static/img/home/home-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .name {
    position: absolute;
    left: 0;
    padding: 0 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .text{
      font-weight: 400;
      padding: 3px 7px;
      border-radius: 5px;
      font-size: 16px;
      border: 1px solid rgb(0, 0, 0);
	  color: rgb(0, 0, 0);
    }
  }
  .title{
    font-weight: 600;
    width: 200px;
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 16px;
  }
  .ling-box{
    position: absolute;
    right: 0;
    padding: 0 13px;
    .ling {
      fill: rgb(2, 2, 2);
      width: 25px;
      height: 25px;
      margin: 0 10px;
      font-size: 13px;
      border-radius: 10px;
    }
  }
}

.userinfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  margin-top: 10px;
  height: 250px;
  align-items: center;
  justify-content: center;
  .text-box{
    width: 100%;
    padding: 15px 10px;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../static/img/home/border.png);
    background-size: 100% 100%;
    border-bottom: none;
    .text1{
      font-size: 20px;
      font-weight: 600;
      line-height: 21px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
    }
    .text2{
      font-size: 14px;
      font-weight: 600;
      color: #999;
      line-height: 16px;
      margin-top: 8px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      text-align: center;
    }
  }

  .p-list{
    margin-top: 20px;
    width: 100%;
    display: flex;
    padding: 10px 0;
    text-align: center;
    .list-item{
      flex: 1;
      width: 77px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 9px;
      .text{
        font-size: 11px;
        font-weight: 500;
        color: #999;
        line-height: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        margin-top: 6px;
        overflow-wrap: break-word;
        word-wrap: break-word;
      }
      .value{
        font-size: 20px;
        font-weight: normal;
        color: rgb(0, 0, 0);
        display: inline-flex;
        text-align: center;
      }
    }
  }
}

.notice-wrap{
  width: 345px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-image: url(../../static/img/home/3-2.png);
  .notice-swipe {
    height: 37px;
    line-height: 37px;
    .van-swipe-item{
      padding: 0;
    }
  }
  .swipe-item{
    height: 16px;
    padding: 9px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
  }
}
.type-wrap{
    background-image: url(../../static/img/home/mb.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 345px;
    border-radius: 10px;
    margin: 15px auto;
    padding: 15px 0;
    box-shadow: 1px 2px 4px rgba(34,61,140,.04);
    display: flex;
  .item-li{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform ease-in-out .5s;
    .horizontal{
      display: flex;
      align-items: center;
        .name{
          font-size: 14px;
          color: black;
          span{
            color: rgb(153, 153, 153);
            font-size: 10px;
          }
        }
    }
    .last{
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 16px;
    }
    .trend-box{
      display: flex;
    }
  }

  .coinimg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
  }
  .greenimg {
    margin-right: 5px;
    width: 20px;
    height: 15px;
  }
  .redimg {
    width: 20px;
    height: 15px;
  }
}
.activeAnim{
  transform: translateY(-20px);
}
.activeAnim1{
  transform: translateY(20px);
}
.rank-wrap{
  background-image: url(../../static/img/home/mb.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 345px;
  border-radius: 10px;
  margin: 15px auto;
  padding: 15px;
  box-shadow: 1px 2px 4px rgba(34,61,140,.04);
  .title-box{
    display: flex;
    font-size: 14px;
    color: #999;
    .ran1{
      flex: 2;
    }
    .ran2{
      flex: 1;
    }
    .ran3{
      flex: 1;
    }
  }
  .list-box{
    .item-li-h{
      display: flex;
      margin-top: 14px;
      transition: transform ease-in-out .5s;
      .horizontal{
        flex: 2;
        display: flex;
        align-items: center;
        .name{
          font-size: 14px;
          color: black;
          span{
            color: rgb(153, 153, 153);
            font-size: 10px;
          }
        }
      }
      .last{
        flex: 1;
        font-size: 16px;
      }
      .trend-box{
        flex: 1;
        display: flex;
      }
    }

    .coinimg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      border-radius: 50%;
    }
    .greenimg {
      margin-right: 5px;
      width: 20px;
      height: 15px;
    }
    .redimg {
      width: 20px;
      height: 15px;
    }
  }
}

.training-steps{
  color: black;
  font-size: 20px;
  width: 345px;
  margin: 20px auto;
}

.video_class {
  width: 100%;
  height: 225px;
  margin: 0 auto;
}
.videos {
  width: 100%;
  height: 100%;
}
.violet-tab{
  background-image: url(../../static/img/home/mb.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 7px;
  width: 345px;
  margin: 15px auto;
  padding: 7px 15px 15px;
  box-shadow: 1px 2px 4px rgba(34,61,140,.04);
  .swipe-item{
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      line-height: 1.6;
      color: black;
      font-size: 16px;
      margin-top: 9px;
    }
    img{
      width: 100%;
      height: 225px;
      margin-top: 9px;
    }
    .text{
      line-height: 1.6;
      color: black;
      font-size: 12px;
      margin-top: 9px;
    }
  }
  .custom-indicator{
    margin-bottom: 10px;
   display: flex;
    justify-content: center;
    .dot{
      margin-right: 8px;
      background-color: rgba(0,0,0,.3);
      width: 8px;
      height: 8px;
      cursor: pointer;
      -webkit-transition-property: background-color;
      transition-property: background-color;
      -webkit-transition-timing-function: ease;
      transition-timing-function: ease;
      border-radius: 50%;
    }
    .active{
      background-color: rgb(0,0,0);
    }
  }
}

.these-step{
  text-align: center;
  margin-bottom: 50px;
  margin-top: 15px;
  padding: 2px 25px;
  color: rgb(0, 0, 0);
  font-size: 16px;
}
.Help-Center{
  color: black;
  font-size: 16px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.help-wrap{
  width: 345px;
  margin: 0 auto 15px;

  :deep(.van-cell){
    padding: 0;
    background-color: transparent;
    align-items: center;
  }
  :deep(.van-collapse-item__content){
    background-color: transparent;
    padding: 15px 0 0 0;
  }
  :deep(.van-collapse-item__title:after){
    content: none;
  }
  :deep(.van-cell__right-icon){
    color: white;
  }
  .collapse-item{
    margin-top: 20px;
    padding: 7px 15px;
    background-image: url(../../static/img/home/mb.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 7px;
    box-shadow: 1px 2px 4px rgba(34,61,140,.04);

    .title-box{
      display: flex;
      align-items: center;
      flex-direction: row;
      box-sizing: border-box;
      font-size: 15px;
      color: #303133;
      .title{
        margin-left: 10px;
        font-size: 14px;
        color: black;
      }
    }
    .text-content{
      color: rgb(72, 72, 72);
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.Partner-box{
  width: 345px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .Partner{
    margin-top: 15px;
    margin-bottom: 8px;
    font-size: 16px;
    color: black;
  }
  .obp{
    color: #c2c8c8;
    font-size: 14px;
  }
  .shouzhang{
    margin-top: -55px;
  }
  .logos{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
    img{
      text-align: center;
      margin-right: 50px;
      margin-bottom: 50px;
      max-height: 30px;
      max-width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.Subscribe-box{
  width: 345px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .Subscribe{
    color: black;
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .subscribe-wrap{
    display: flex;
    align-items: center;
    width: 345px;
    height: 40px;
    background-color: rgb(157, 196, 255);
    border-radius: 10px;
    .subscribe-input{
      background-color: transparent;
    }
    .btn{
      padding: 0 12px;
      font-size: 10px;
      background-image: linear-gradient(to right, rgb(0, 117, 253), rgb(0, 117, 253));
      border-radius: 8px;
      width: 90px;
      height: 40px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.dash-line{
  margin: 24px auto;
  width: 345px;
  border-bottom: 1px dashed;
  background-color: rgba(0, 117, 253, 0.2);
  border-radius: 7px;
}

.service-box{
  width: 345px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title{
    font-size: 24px;
    font-weight: bold;
    color: black;
  }
  .sub-title{
    font-size: 15px;
    margin-top: 7px;
    color: black;
  }
}

.ragkuang {
  width: 100%;
  color: black;
  .Website{
    font-size: 16px;
    font-weight: 700;
  }
  .Uptime{
    font-size: 13px;
    margin-top: 5px;
  }
}

.HelpCenter-box{
  width: 345px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  .text{
    font-size: 14px;
    margin-bottom: 14px;
  }
}

.bottom-box{
  width: 345px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  .bottom-title{
    font-weight: 600;
    font-size: 32px;
  }
  .bottom-text{
    margin-top: 19px;
    margin-bottom: 15px;
    font-size: 13px;
  }
}
/***************************************************************************************************/
.margintop40 {
  margin-top: 40px;
}
.margintop10 {
  margin-top: 10px;
}
.margintop20 {
  margin-top: 10px;
}
.info_truess {
  width: 100%;
  height: 69px;
  border-radius: 6px;
  background: #16182b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.infop3 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #9b9ea4;
  text-align: left;
}
.infop4 {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}
.lunbo {
  width: 100%;
  margin: 0 auto;
  margin-top: 70px;
}
.lunbop1 {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  background: linear-gradient(270deg, #f0faff 0%, #d5f2ff 31.5%, #8194ff 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.lunbo_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 158px;
  height: 32px;
  border-right: 1px solid #ffffff;
  margin-right: 20px;
  padding-right: 20px;
}
.lunbo_info img {
	width: 30px;
  height: 30px;
}
.lunbo_info_kuang_p1 {
  font-size: 12px;
  font-weight: 400;
  color: #9b9ea4;
}
.lunbo_info_kuang_p2 {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
}
/deep/ .van-notice-bar__content {
  display: flex;
}
.txtred {
  color: #f6475d;
}
.txtgreen {
  color: #0dcb81;
}
.fs12 {
  font-size: 12px;
}
.fs13_5 {
  font-size: 13.5px;
}
.fs14 {
  font-size: 14px;
}
.fs16 {
  font-size: 16px;
}
.fs18 {
  font-size: 18px;
}
.fs24 {
  font-size: 24px;
}
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.sjdbaise {
  color: #9b9ea4;
}
.textcolor1 {
  color: #a1abcf;
}
.w33 {
  width: 33.33%;
}
.textl {
  text-align: left;
}
.textc {
  text-align: center;
}
.textr {
  text-align: right;
}
.coinlist {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  background: #16182bcc;
  padding: 24px 16px 24px 16px;
}
.cointitle {
  text-align: center;
  background: linear-gradient(270deg, #f0faff 0%, #d5f2ff 31.5%, #8194ff 100%);
  -webkit-background-clip: text;
  color: transparent;
}
.clisttitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.coininfo {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid #252b42;
  padding-bottom: 15px;
}
.coininfoleft {
  display: flex;
  align-items: center;
  justify-content: left;
}

.aitext {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}
.aitext_title {
  text-align: center;
  background: linear-gradient(270deg, #f0faff 0%, #d5f2ff 31.5%, #8194ff 100%);
  -webkit-background-clip: text;
  color: transparent;
}
.nextstep {
  width: 100%;
  margin-top: 80px;
  overflow-y: auto;
  display: flex;
}
.step_kuang {
  flex-shrink: 0;
  width: 293px;
  height: 465px;
  border-radius: 12px;
  border: 0.75px;
  background: linear-gradient(
    155.14deg,
    rgba(56, 48, 110, 0) -2.13%,
    rgba(255, 255, 255, 0.13) 136.58%
  );
  border: 0.75px solid;
  padding: 0 26px;
  margin-right: 20px;
  border-image-source: linear-gradient(
    158.58deg,
    rgba(149, 142, 200, 0.3) 6.13%,
    rgba(149, 142, 200, 0) 105.87%
  );
}
.stepcount {
  width: 65px;
  height: 21px;
  line-height: 21px;
  border-radius: 18px;
  padding: 0 9.75px;
  margin-top: 26.75px;
  text-align: center;
  background: linear-gradient(
    270deg,
    rgba(240, 250, 255, 0.1) 0%,
    rgba(213, 242, 255, 0.1) 31.5%,
    rgba(129, 148, 255, 0.1) 100%
  );
}
.beijingtext {
  background: linear-gradient(270deg, #f0faff 0%, #d5f2ff 31.5%, #8194ff 100%);
  -webkit-background-clip: text;
  color: transparent;
}
.steptitle {
  margin-top: 30px;
}
.stepcontent {
  margin-top: 30px;
  text-align: justify;
}
.stepim {
  width: 100%;
  height: 203px;
  border-radius: 6px;
  margin-top: 30px;
}
.fqinfo {
  width: 90%;
  margin: 0 auto;
  margin-top: 70px;
}
.fqtitle {
  text-align: center;
  background: linear-gradient(270deg, #f0faff 0%, #d5f2ff 31.5%, #8194ff 100%);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 30px;
}
.qfcontent {
  text-align: justify;
}
.fqheng {
  margin-top: 20px;
  height: 1px;
  background: #3e4769;
}
.fqxq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
}
.walletall {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}
.wallettitle {
  text-align: center;
  background: linear-gradient(270deg, #f0faff 0%, #d5f2ff 31.5%, #8194ff 100%);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 30px;
}
.walletimg {
  width: 100%;
}
.allservice {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}
.allservicetitle {
  text-align: center;
  background: linear-gradient(270deg, #f0faff 0%, #d5f2ff 31.5%, #8194ff 100%);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
}
.redandgreen {
  width: 100%;
}
.ragkuang {
  width: 100%;
  margin-top: 20px;
  background: linear-gradient(
    178.65deg,
    rgba(56, 48, 110, 0) -82.81%,
    rgba(255, 255, 255, 0.13) 138.19%
  );
}
.ragtitle {
}
.ragbaifenbi {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rag14 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
  height: 30px;
}
.greenshubiao {
  width: 5px;
  height: 100%;
  border-radius: 7px;
  background: #0dcb81;
}
.redshubiao {
  width: 5px;
  height: 100%;
  border-radius: 7px;
  background: #f6475d;
}
.protip {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}
.tiotitle {
  text-align: center;
  background: linear-gradient(270deg, #f0faff 0%, #d5f2ff 31.5%, #8194ff 100%);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
}
.procontent {
  width: 100%;
  display: flex;
  overflow-x: auto;
}
.tipsctent {
  flex-shrink: 0;
  width: 193.55px;
  height: 209px;
  margin-right: 20px;
}
.tipsimg {
  width: 193.55px;
  height: 153px;
  margin-bottom: 10px;
}
.bottomtext {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 30px;
  border-top: 1px solid #3e4769;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.truess_img{
	width: 24px;
	height: 24px;
}
.fqinfoimg{
	width: 18px;
	height:18px;
}



.mask-news-info {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mask-news-info .reg-model {
    width: 327px;
    height: 409px;
    border-radius: 12px;
    overflow: hidden;
}
.ming-wrap{
	padding: 0 12px;
}
.col, .flex-col, .column {
    display: flex;
    flex-direction: column;
}
.ming-wrap .item-li {
    height: 100%;
}
.ming-wrap .item-li .usdInput {
    margin-bottom: 7px;
}
.btn {
    height: 41px;
    background: #1a252b;
    border-radius: 8px;
    border: 1px solid #aaafc2;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    padding: 0 13px;
}
.center, .c {
    display: flex;
    align-items: center;
    justify-content: center;
}
.u-icon--right {
    flex-direction: row;
    align-items: center;
}
.u-icon {
    display: flex;
    align-items: center;
}
.u-icon__icon {
    font-family: uicon-iconfont;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}
</style>
