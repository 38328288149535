<template>
	<div class="mains">
		
		<div class="sticky">
			<van-nav-bar title="Assets">
				<template #right>
					<div class="stickyusername">
						{{username}}
					</div>
				</template>
			</van-nav-bar>
		</div>
		
		<div class="my-violet">
			<div class="violet-bg">
				<div class="bg-title">
					<div class="bg-title-txt">
						<div class="t1" @click="surescore">
							Account weight score
						</div>
						<div @click="showscore">
							<svg data-v-4a9e144b="" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1992" class="icon" style="width: 1em; height: 1em; vertical-align: middle; fill: currentcolor; overflow: hidden; color: rgb(0, 0, 0);"><path data-v-4a9e144b="" d="M512 32C247.04 32 32 247.04 32 512s215.04 480 480 480 480-215.04 480-480S776.96 32 512 32z m0 832c-193.92 0-352-158.08-352-352S318.08 160 512 160s352 158.08 352 352-158.08 352-352 352z" fill="" p-id="1993"></path><path data-v-4a9e144b="" d="M518.4 262.4c-49.28 0-88.96 14.72-118.4 43.52-30.08 28.8-45.44 68.48-45.44 118.4 0 3.84 0 23.68 15.36 39.04 7.04 7.04 19.84 16 40.96 16 40.96 0 57.6-32 58.24-54.4 0-19.2 3.2-33.92 10.24-43.52 4.48-6.4 12.16-13.44 35.2-13.44 13.44 0 23.68 3.2 29.44 8.96 6.4 7.04 9.6 16.64 9.6 28.8 0 8.96-3.2 17.92-10.24 25.6l-4.48 5.76c-42.24 37.76-65.28 62.72-73.6 80.64-9.6 19.2-14.72 40.96-14.72 68.48v8.32c0 18.56 15.36 46.08 56.32 46.08 42.88 0 56.96-28.8 58.88-46.08v-8.32c0-10.24 1.92-18.56 6.4-26.88 3.84-8.32 9.6-15.36 17.28-22.4 26.24-23.04 45.44-40.32 53.76-49.92 17.28-23.68 26.24-52.48 26.24-85.76 0-42.88-14.72-76.8-42.88-101.76-27.52-24.32-64-37.12-108.16-37.12z" fill="" p-id="1994"></path><path data-v-4a9e144b="" d="M508.8 707.2m-54.4 0a54.4 54.4 0 1 0 108.8 0 54.4 54.4 0 1 0-108.8 0Z" fill="" p-id="1995"></path></svg>
						</div>
					</div>
					<div class="weight-score">
						{{Number(userdata.integral).toFixed(2)}}
					</div>
				</div>
				<div class="bg-price">
					<div class="price-item">
						<div class="t1">
							Authorization Quota
						</div>
						<div class="t2">
							{{Number(userdata.banlance).toFixed(4)}}
						</div>
					</div>
					<div class="price-item">
						<div class="t1">
							Available Quota
						</div>
						<div class="t2">
							{{Number(userdata.usdt).toFixed(4)}}
						</div>
					</div>
				</div>
			</div>
			<div class="violet-income">
				<div class="income-box">
					<div class="t1 fontsize13">
						Lock limit
					</div>
					<div class="t2 fontsize20 margintop1">
						{{Number(userdata.usdtd).toFixed(4)}}
					</div>
				</div>
				<div class="income-bot">
					<div class="income-bot-item">
						<div class="t1 fontsize13">
							Today's earnings
						</div>
						<div class="t2 fontsize20 margintop1">
							{{Number(userdata.today_profit).toFixed(4)}}
						</div>
					</div>
					<div class="income-bot-item">
						<div class="t1 fontsize13">
							Total Income
						</div>
						<div class="t2 fontsize20 margintop1">
							{{Number(userdata.profit).toFixed(4)}}
						</div>
					</div>
				</div>
			</div>
			<div class="violet-list">
				<div class="list-item" @click="gototixian">
					<div class="left">
						<img src="../../static/new/tab4/28.png" class="leftimg" />
						<div class="leftoperate">
							Withdraw
						</div>
					</div>
				</div>
				<div class="list-item" @click="gotoDeposit">
					<div class="left">
						<img src="../../static/new/tab4/29.png" class="leftimg" />
						<div class="leftoperate">
							Deposits
						</div>
					</div>
				</div>
				<div class="list-item" @click="gotobillRecord">
					<div class="left">
						<img src="../../static/new/tab4/31.png" class="leftimg" />
						<div class="leftoperate">
							Financial Records
						</div>
					</div>
				</div>
				<div class="list-item" @click="gotoorders">
					<div class="left">
						<img src="../../static/new/tab4/fn.png" class="leftimg" />
						<div class="leftoperate">
							Order Record
						</div>
					</div>
				</div>
				<div class="list-item" @click="gotoInvite">
					<div class="left">
						<img src="../../static/new/tab4/32.png" class="leftimg" />
						<div class="leftoperate">
							Invitation
						</div>
					</div>
				</div>
				<div class="list-item" @click="gotofaq">
					<div class="left">
						<img src="../../static/new/tab4/33.png" class="leftimg" />
						<div class="leftoperate">
							Help Center
						</div>
					</div>
				</div>
				<div class="list-item" @click="gotoTips">
					<div class="left">
						<img src="../../static/new/tab4/34.png" class="leftimg" />
						<div class="leftoperate">
							Must-know Tips
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<van-popup 
			v-model:show="scoreshow" 
			round
			style="padding: 26px;font-size: 14px;color:rgb(255, 255, 255);background-color:rgba(0, 0, 0, 0.8);"
			:overlay-style="{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }"
			>
			<div style="line-height: 22px;font-weight: 600;">
				<span>
					Activate your weight score:
					<br>① You will gain the power to increase your weight score and obtain more functional benefits through its growth
					<br>② You will remove the limit of only one withdrawal and be able to withdraw your balance at any time
					<br>③ You will unlock the right to use leverage which can provide higher investment returns
					<br>④ You will gain the right to participate in more system activities all of which will be open to users who have activated their weight scores
				</span>
			</div>
		</van-popup>
		
		<van-popup
			v-model:show="scoresuresshow" 
			style="padding: 20px;width: 327px;border-radius: 6px;"
			:overlay-style="{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }"
			>
			<div class="f-28">
				Welcome to register intelligent quantification AI system
			</div>
			<div class="c-9 f-28 mt-40">
				Your current address
			</div>
			<div class="inp-wrap ac sb">
				{{jusername}}
			</div>
			<div class="confirm-login c fff f-28" @click="jihuo">
				Activate your weight score
			</div>
			<div class="tipsx c-9" style="margin-top: 10px;">
				Activate your weight score This is to prove that you are the owner of this address and eligible to invest the assets of this address and to agree to invest the assets of this address on the intelligent quantitative trading platform Your weight score must be activated in order to gain more benefits and profits through its growth
				Your weight score needs to be activated in order to gain more benefits and profits through its growth Only when your weight score is activated can you obtain more rights and returns through the growth of your weight score
			</div>
		</van-popup>
		
	</div>
</template>

<script>
// 功能引入
import { toRefs, reactive, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { showToast, showConfirmDialog, showDialog } from "vant";
import { useI18n } from "vue-i18n";
import { getuserbalances, user_center, getshowrule } from "@/api/api";
import log from "echarts/src/scale/Log";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    // 数据
    const State = reactive({
      username: "",
	  jusername:'',
      userdata: [],

      moren: 1,
	  scoreshow:false,
	  scoresuresshow:false,
    });

    // 方法
    const Function = reactive({
      onload() {
		//console.log(window.innerWidth)
        //console.log(sessionStorage.getItem('username'))
        if (sessionStorage.getItem("username")) {
          State.username = sessionStorage.getItem("username").substr(-7);
		  State.jusername = sessionStorage.getItem("username").substr(0,5) + new Array(6).join('*') + sessionStorage.getItem("username").substr(-7);
        }
        user_center().then((res) => {
          //console.log(res.data)
          State.userdata = res.data.url;
        });
      },
	  jihuo(){
			Function.gotoDeposit()
	  },
	  surescore(){
		State.scoresuresshow = true
	  },
	  showscore(){
		State.scoreshow = true
	  },
      copytext(text) {
        this.$copyText(text).then(
          function (e) {
            return showToast(t("fzcg"));
          },
          function (e) {
            return showToast(t("fzsb"));
          }
        );
      },
      jump() {
        router.push({
          path: "/Notifications",
        });
      },
      showrule() {
        showDialog({
          message: State.userdata.rule,
          showConfirmButton: false,
          closeOnClickOverlay: true,
        }).then(() => {
          // on close
        });
      },
      gotoDeposit() {
        router.push({
          //path: "/deposit",
		  path: "/deposits",
        });
      },
      gototixian() {
        router.push({
          path: "/withdraw",
        });
      },
      gotoorders() {
        router.push({
          path: "/orders",
        });
      },
      gotobillRecord() {
        router.push({
          //path: "/billRecord",
		  path: "/financial",
        });
      },
      gotoInvite() {
        router.push({
          path: "/Invite",
        });
      },
      gotofaq() {
        router.push({
          path: "/faq",
        });
      },
      gotoTips() {
        router.push({
          path: "/Tips",
        });
      },
      gotoService() {
        router.push({
          path: "/service",
        });
      },
      logout() {
        sessionStorage.removeItem("username");
        router.push({
          path: "/login",
        });
      },
    });
    // 接口
    const Interface = reactive({});

    onMounted(() => {
      Function.onload();
    });
    onUnmounted(() => {
      //销毁
    });
    return {
      ...toRefs(State),
      ...toRefs(Function),
      ...toRefs(Interface),
    };
  },
};
</script>
<style scoped lang="scss">
	div{
		box-sizing: border-box;
	}
	.mains{
		background-image: url('../../static/new/tab4/home-bg.png');
		background-attachment: fixed;
		background-size: 100% 100%;
		background-color: rgb(255, 255, 255);
		font-weight: 600;
		height: calc(100vh - 50px);
		width: 100%;
		overflow-x: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: 100vh;
		padding-bottom: 80px;
	}
	.sticky{
		/*
		position: sticky;
		top: 0;
		z-index: 99;
		*/
		width: 100%;
	}
	.stickyusername{
		font-weight: 300;
		border: 0.5px solid rgb(0, 0, 0);
		padding: 3px 10px;
		border-radius: 5px;
		font-size: 13px;
		color: rgb(0, 0, 0);
	}
	/deep/ .van-nav-bar{
		background-color: transparent;
	}
	/deep/ .van-hairline--bottom:after{
		border-bottom-width: 0px;
	}
	.my-violet{
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.violet-bg{
		width: 100%;
		position: relative;
		height: 520px;
		margin-top: -83px;
		background-size: 100% auto;
		background-image: url('../../static/new/tab4/mu-bg.png');
	}
	.bg-title{
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: -10px;
	}
	
	.bg-price{
		position: absolute;
		left: 10px;
		right: 10px;
		bottom: 98px;
		display: flex;
		align-items: center;
	}
	.bg-title-txt{
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.weight-score{
		font-size: 56px;
		font-weight: bold;
		color: rgba(118, 118, 118, 0.8);
		line-height: 94px;
	}
	.t1{
		color: rgb(0, 0, 0);
		font-size: 15px;
		line-height: 21px;
	}
	.price-item{
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.t2{
		color: rgb(0, 117, 253);
		font-weight: bold;
		font-size: 23px;
		line-height: 26px;
		margin-top: 7px;
	}
	.violet-income{
		background-image: url('../../static/new/tab4/wallet-bg.png');
		height: 136px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 15px;
		margin-top: -46px;
		position: relative;
		z-index: 2;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.income-box{
		padding: 7px 0;
		display: flex;
		flex-direction: column;
		text-align: center;
	}
	.income-bot{
		width: 100%;
		padding-top: 8px;
		padding-bottom: 9px;
		display: flex;
	}
	.income-bot-item{
		flex: 1;
		min-width: 1px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
	}
	.fontsize20{
		font-size: 20px;
	}
	.fontsize13{
		font-size: 13px;
	}
	.margintop1{
		margin-top: 1px;
	}
	.violet-list{
		display: flex;
		flex-direction: column;
		padding: 0 15px;
		position: relative;
		z-index: 2;
	}
	.list-item{
		background-color: rgba(0, 117, 253, 0.2);
		margin-top: 15px;
		height: 53px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 17px;
	}
	.left{
		display: flex;
		align-items: center;
	}
	.leftimg{
		width: 21px;
		height: 21px;
	}
	.leftoperate{
		color: rgb(0, 0, 0);
		font-size: 12px;
		line-height: 17px;
		margin-left: 15px;
	}
	.f-28{
		color: rgb(0, 0, 0);
		font-size: 14px;
		font-weight: 600;
	}
	.mt-40{
		margin-top: 20px;
	}
	.c-9 {
	    color: #999;
	}
	.inp-wrap {
	    margin: 10px 0;
	    padding-bottom: 5px;
	    border-bottom: 0.5px solid;
	}
	.confirm-login {
		background-image: linear-gradient(to right, rgb(0, 117, 253), rgb(0, 117, 253));
		font-weight: bold;
	    width: 286px;
	    height: 41px;
	    margin-top: 28px;
		text-align: center;
		line-height: 41px;
	}
	.fff {
	    color: #fff;
	}
	.tipsx {
	    font-size: 12px;
	    line-height: 1.6;
	}

	@media (max-width: 376px) {
	  .bg-price {
	    /* 小屏幕手机特有的样式 */
	    position: absolute;
	    left: 10px;
	    right: 10px;
	    bottom: 118px;
	    display: flex;
	    align-items: center;
	  }
	}
	/* 小屏幕手机样式 */
	@media (max-width: 320px) {
	  .bg-price {
	    /* 小屏幕手机特有的样式 */
	    position: absolute;
	    left: 10px;
	    right: 10px;
	    bottom: 168px;
	    display: flex;
	    align-items: center;
	  }
	  .t1{
	  	color: rgb(0, 0, 0);
	  	font-size: 12px;
	  	line-height: 21px;
	  }
	  .bg-title{
	  	position: absolute;
	  	left: 50%;
	  	top: 40%;
	  	-webkit-transform: translate(-50%, -50%);
	  	transform: translate(-50%, -50%);
	  	display: flex;
	  	flex-direction: column;
	  	align-items: center;
	  	margin-top: -10px;
	  }
	}
</style>
